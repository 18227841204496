.ui-state-default, .ui-widget-content .ui-state-default {
    background-size: 100% 100%;
}

.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active {
    border-right: 1px solid #c1c1ad;
    border-left: 1px solid #c1c1ad;
    background: #ffffff;
    font-weight: bold;
    color: #000000;
    z-index: 1!important;
}

.ui-accordion .ui-accordion-header .ui-accordion-header-icon {
    position: absolute;
    left: .5em;
    top: 50%;
    margin-top: -8px;
}

.ui-widget.ui-widget-content {
    border: none;
}

div.recampus.ui-tabs-panel {
    background-color: #FFFFFF;
}
